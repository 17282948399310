import "../css/main.scss";

const recaptchaKey = "6Ld-lZgUAAAAAKQFqL-Q9LqOBsQ8ho_6AMNhq-C3";

const sessionStorage = window.sessionStorage;

window.onload = init;

function init() {
    const mainContainer = document.getElementById("hp-container");
    const requestDemoForm = document.getElementById("rd-form");
    setSessionCampaignDetails();
    mainContainer && addScrollStickEffect();
    requestDemoForm && requestDemoForm.addEventListener("submit", requestDemo);   
}

function addScrollStickEffect() {
    window.addEventListener("scroll", () => {
        var header = document.querySelector("header");
        header.classList.toggle("sticky-effect", window.scrollY > 0);
    });
}




function requestDemo(e) {
    const defaultContent = e.submitter.innerHTML; 
    const form = e.target;
    const submitter = e.submitter;
    e.preventDefault();
    e.stopPropagation();
    disableError();
    submitter.disabled = true;
    submitter.innerHTML = `<div class="f-loading"></div>`;
    grecaptcha.ready(function() {
        grecaptcha.execute(recaptchaKey, {action: "submit"}).then(function(recaptchaToken) {
            submitRequest(recaptchaToken, {
                name: form["name"].value,
                email: form["email"].value,
                company: form["company"].value,
                info: form["info"].value,
            }).then(showSuccess)
            .catch((err) => {
                console.error("ERROR IN REQUEST DEMO FORM: ", err);
                submitter.innerHTML = defaultContent;
                submitter.disabled = false;
                enableError();
            });
        });
    });
    return false;
}

function submitRequest(recaptchaToken, contactData) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            try {
                var request = new XMLHttpRequest();
                var campaignDetails = getSessionCampaignDetails();
                var formData = {
                  "origin": window.location.hostname,
                  "name": contactData.name,
                  "email": contactData.email,
                  "company": contactData.company,
                  "info": contactData.info,
                  "campaignSource": campaignDetails.campaignSource,
                  "campaignMedium": campaignDetails.campaignMedium,
                  "campaignName": campaignDetails.campaignName,
                  "campaignContent": campaignDetails.campaignContent,
                  "campaignTerm": campaignDetails.campaignTerm,
                  "recaptchaToken": recaptchaToken
                }; 
                request.onreadystatechange = function() {
                  if (request.readyState == XMLHttpRequest.DONE && (request.status >= 200 && request.status < 400)) {
                    resolve(request.response);
                  } else if(request.readyState == XMLHttpRequest.DONE && request.status >= 400 ) {
                    reject(request.response);
                  }
                }
                request.onerror = reject;
                request.open("POST", "https://api.flexbundle.com/v1/function/02000001/request_demo", true);
                request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                request.send(JSON.stringify(formData));
            } catch(e) { 
                reject(e) ;
            } 
        });
    });
}

function setSessionCampaignDetails() {
    var self = window.location.toString();
    var querystring = self.split("?");
    if (querystring.length > 1) {
      var pairs = querystring[1].split("&");
      for (let i in pairs) {
        var keyval = pairs[i].split("=");
        if (sessionStorage.getItem(keyval[0]) === null) {
            sessionStorage.setItem(keyval[0], decodeURIComponent(keyval[1]));
        }
      }
    }
}

function getSessionCampaignDetails() {
    return {
        campaignSource: sessionStorage.getItem("utm_source"),
        campaignName: sessionStorage.getItem("utm_campaign"),
        campaignMedium: sessionStorage.getItem("utm_medium"),
        campaignContent: sessionStorage.getItem("utm_content"),
        campaignTerm: sessionStorage.getItem("utm_term"),
    }
}

function showSuccess() {
    window.location.href = "/success_request.html";
}

function enableError() {
    const errorEl = document.getElementById("rd-form-error");
    errorEl && errorEl.classList.add("show");
}

function disableError() {
    const errorEl = document.getElementById("rd-form-error");
    errorEl && errorEl.classList.remove("show");
}